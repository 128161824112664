import commons from '@/plugins/commons'
import utils from '@/plugins/utils'

const defaultState = () => {
  return {
    selectedStore: {},
    searchStoreItem: [],
    managedStores: [],
    activateFeatures: {},
    permissionKey: {},
    permissionValue: {},
    emailaddress: '',
    savePreviewSet: {},
    currentLanguage: '',
    LoginMainImage: '',
    LoginLogoImage: '',
    MenuLogoImage: '',
    sideMenu: '',
    accessMenu: [
      { code: '1000', name: 'Overview' },
      { code: '2000', name: 'Label Info' },
      { code: '2100', name: 'Label Info' },
      { code: '2600', name: 'Label Config' },
      { code: '2200', name: 'Fail Records' },
      { code: '2300', name: 'Label Firmware' },
      { code: '3000', name: 'Product Info' },
      { code: '3100', name: 'Product Info' },
      { code: '3200', name: 'Update Records' },
      { code: '4000', name: 'Gateway Info' },
      { code: '4100', name: 'Gateway Info' },
      { code: '4200', name: 'Gateway Firmware' },
      { code: '5000', name: 'Template Info' },
      { code: '5100', name: 'Template Type List' },
      { code: '5200', name: 'Template Grouping' },
      { code: '5500', name: 'Template Model Grouping' },
      { code: '5300', name: 'Template Mapping' },
      { code: '5400', name: 'Image on Template' },

      { code: '6000', name: 'Multifunction Info' },
      { code: '6100', name: 'LBS Info' },
      { code: '6600', name: 'LBS_Shelf Info' },
      { code: '6200', name: 'LED Info' },
      { code: '10000', name: 'Firmware Info' },
      { code: '10100', name: 'Label Firmware' },
      { code: '10300', name: 'Gateway Firmware' },
      { code: '10200', name: 'Label OTA' },
      { code: '6300', name: 'Button Info' },
      { code: '7000', name: 'Store Info' },
      { code: '7100', name: 'Store Info' },
      { code: '7200', name: 'White List' },
      { code: '8000', name: 'User Info' },
      { code: '8100', name: 'User Info' },
      { code: '8200', name: 'User Mapping' },
      { code: '8300', name: 'User Level' },
      { code: '8400', name: 'My Account' },
      // For Metro
      { code: '11000', name: 'Reports' },
      { code: '11100', name: 'Error List' },
      { code: '11200', name: 'ESL Product Information' },

      { code: '9000', name: 'System Config' },
      { code: '9100', name: 'Dashboard Settings' },
      { code: '9200', name: 'Hardware Settings' },
      { code: '9300', name: 'App Config' },
      { code: '9500', name: 'Product File Config' },
      { code: '9400', name: 'System License' },

      // Dicamo Menu List
      // { code: '20000', name: 'LCD' },
      { code: '21000', name: 'Overview' },
      { code: '22000', name: 'LCD' },
      { code: '22100', name: 'LCD Info' },
      { code: '23000', name: 'Contents' },
      { code: '23100', name: 'Media Info' },
      { code: '23200', name: 'Product Info' },
      { code: '24000', name: 'Playlist Group' },
      { code: '24100', name: 'Playlist Group Info' },
      { code: '25000', name: 'Template' },
      { code: '25100', name: 'Template Info' }

      // Dicamo END

    ],
    accessAction: [
      { code: '2000', name: 'Label Info' },
      { code: '2100', name: 'Label Info : Label Unassign / Delete' },
      { code: '2104', name: 'Label Info  : Image Push' },
      { code: '2101', name: 'Label Info : LED Blinking / On-Demand Alive / Resend / Resend All / LED Blinking All / Page flip All' },
      { code: '2102', name: 'Label Info : Export' },
      { code: '2103', name: 'Label Info : Detail Popup' },
      { code: '2601', name: 'Button Config  : Save Button Settings' },
      { code: '2200', name: 'Fail Records : Export' },
      { code: '2300', name: 'Firmware : Firmware List' },
      { code: '2301', name: 'Firmware : Firmware Update' },
      { code: '2302', name: 'Firmware : Export' },
      { code: '3000', name: 'Product Info' },
      { code: '3100', name: 'Product Info : Detail Popup' },
      { code: '3101', name: 'Product Info : File Upload' },
      { code: '3102', name: 'Product Info : Add / Delete' },
      { code: '3103', name: 'Product Info : LED Blinking' },
      { code: '3104', name: 'Product Info : Export' },
      { code: '3200', name: 'Update Records : Export' },
      { code: '4000', name: 'Gateway Info' },
      { code: '4100', name: 'Gateway Info : Get Detailed' },
      { code: '4101', name: 'Gateway Info : Add / Delete' },
      { code: '4102', name: 'Gateway Info : Reboot' },
      { code: '4103', name: 'Gateway Info : Export' },
      { code: '4200', name: 'Gateway Firmware : Update' },
      { code: '4201', name: 'Gateway Firmware : Export' },
      { code: '5000', name: 'Template Info' },
      { code: '5100', name: 'Template Type List : New / Delete / Save / Reapply' },
      // { code: '5101', name: 'Template Type List : Export' },
      { code: '5200', name: 'Template Grouping : New Group / Edit / Delete' },
      { code: '5500', name: 'Template Model Grouping : New Group / Edit / Delete' },
      {
        code: '5300',
        name: 'Template Mapping : New / Delete / Save / Apply'
      },

      { code: '5400', name: 'Image on Template : File Upload / Delete' },
      // { code: '5401', name: 'Image on Template : Export' },

      { code: '6000', name: 'Multifunction Info' },
      { code: '6100', name: 'LBS Info : New Anchor / Delete / Setting' },
      { code: '6600', name: 'LBS_Shelf Info : Add / Edit / Delete' },
      { code: '6200', name: 'LED Info : Edit Pattern / Save' },
      { code: '10000', name: 'Firmware Info' },
      { code: '10100', name: 'Label Firmware: File Upload' },
      { code: '10300', name: 'Gateway Firmware: File Upload / Delete' },
      { code: '10200', name: 'Label OTA: On / Off / OTA Setting Save' },
      // { code: '10201', name: 'Label OTA: OTA Setting Popup' },
      { code: '7000', name: 'Store Info' },
      { code: '7100', name: 'Store Info : Detail Popup' },
      { code: '7101', name: 'Store Info : Add / Delete / Setting' },
      { code: '7102', name: 'Store Info : Export' },
      { code: '7103', name: 'Country/Region/City : Add' },
      { code: '7200', name: 'Whitelist : Save / Add / Delete' },
      { code: '7201', name: 'Whitelist : Export' },
      { code: '8000', name: 'User Info' },
      { code: '8100', name: 'User Info : New / Delete' },
      { code: '8101', name: 'User Info : Detail Popup' },
      { code: '8102', name: 'User Info : Export' },
      { code: '8200', name: 'User Mapping : Add / Delete' },
      { code: '8300', name: 'User Level : Save / Reset' },
      { code: '8400', name: 'My Account : Save' },
      { code: '8401', name: 'Password Edit' },
      // For Metro
      { code: '11000', name: 'Reports' },
      { code: '11100', name: 'Error List' },
      { code: '11200', name: 'ESL Product Information' },

      { code: '9000', name: 'System Config' },
      { code: '9100', name: 'Dashboard Settings: Save' },
      { code: '9101', name: 'Country/Region/City : Get / Add / Delete' },
      { code: '9102', name: 'Alive Interval : Save' },
      { code: '9200', name: 'Hardware Settings : Save' },
      { code: '9201', name: 'Mail Settings : Save' },
      { code: '9300', name: 'APP Settings : Save / Delete' },
      // { code: '9400', name: 'Activation' },
      { code: '9500', name: 'Product File Config : Save' },
      { code: '9400', name: 'Activation' },

      // Dicamo Action List
      // { code: '20000', name: 'LCD' },
      { code: '21000', name: 'Overview' },
      { code: '22000', name: 'LCD' },
      { code: '22100', name: 'LCD List' },
      { code: '22001', name: 'Playlist Group Mapping / Assing' },
      { code: '22002', name: 'Registration' },
      { code: '22003', name: 'Edit / Delete' },
      { code: '22004', name: 'Sleep Schedule' },
      { code: '22005', name: 'Detail Popup' },
      { code: '22006', name: 'Schedule Callendar' },
      { code: '22007', name: 'Export' },
      { code: '23000', name: 'Contents' },
      // { code: '23100', name: 'Media Info' },
      { code: '23101', name: 'Media Info : Video Add / Edit / Delete' },
      { code: '23102', name: 'Media Info : Image Add / Edit / Delete' },
      { code: '23200', name: 'Product Info : Detail Popup' },
      { code: '23201', name: 'Product Info : Update / Add / Delete' },
      { code: '23202', name: 'Product Info : Export' },
      // { code: '23203', name: 'Product Detail' },
      { code: '24000', name: 'Playlist Group' },
      // { code: '24100', name: 'Playlist List' },
      { code: '24001', name: 'New / Edit / Delete' },
      { code: '24002', name: 'Set / Delete Default Group' },
      { code: '24003', name: 'Export' },
      { code: '24004', name: 'Playlist Group Detail' },
      // { code: '25000', name: 'Template' },
      { code: '25100', name: 'Template List' },
      { code: '25001', name: 'New / Edit / Delete' }
      // { code: '25002', name: 'Template Detail' }

      // Dicamo END

    ],
    staySignedIn: false,
    timezone: ''
  }
}
const state = defaultState()

export const dataStore = {
  getters: {
    GET_CURRENT_LANGUAGE: state => state.currentLanguage,
    GET_SELECTED_STORE: state => state.selectedStore,
    IS_SELECTED_STORE: state => {
      return state.selectedStore ?.name !== undefined
    },
    GET_STORE_ITEMS: state => state.managedStores,
    GET_MENU: state => state.accessMenu,
    GET_ACTION: state => state.accessAction,
    GET_TIMEZONE: state => state.timezone
  },
  mutations: {
    UPDATE_CURRENT_LANGUAGE: (state, code) => {
      state.currentLanguage = code
    },
    UPDATE_SELECTED_STORE: (state, store) => {
      state.selectedStore = Object.assign({}, store)
    },
    UPDATE_MANAGED_STORE: (state, data) => {
      state.managedStores = [...data.managedStores]
    },
    addManagedStore: (state, store) => {
      state.managedStores = [...state.managedStores, store]
    },
    INIT_SELECTED_STORE: (state, user) => {
      state.managedStores = user.managedStores
    },
    CLEAN: (state, payload) => {
      defaultState.currentLanguage = state.currentLanguage
      state = defaultState()
    },
    updateStaySignedIn: (state, staySignedIn) => {
      state.staySignedIn = staySignedIn
    },
    updateActivateFeatures: (state, activateFeatures) => {
      state.activateFeatures = activateFeatures
    },
    updatePermissionKey: (state, permissionKey) => {
      state.permissionKey = permissionKey
    },
    updatePermissionValue: (state, permissionValue) => {
      state.permissionValue = permissionValue
    },
    updateemailAddress: (state, emailaddress) => {
      state.emailaddress = emailaddress
    },
    updatesavePreviewSet: (state, savePreviewSet) => {
      state.savePreviewSet = savePreviewSet
    },
    updateLoginMainImage: (state, LoginMainImage) => {
      state.LoginMainImage = LoginMainImage
    },
    updateLoginLogoImage: (state, LoginLogoImage) => {
      state.LoginLogoImage = LoginLogoImage
    },
    updateMenuLogoImage: (state, MenuLogoImage) => {
      state.MenuLogoImage = MenuLogoImage
    },
    updateTimezone: (state, timezone) => {
      state.timezone = timezone
    },
    updateSidebarMenu: (state, sideMenu) => {
      state.sideMenu = sideMenu
    }

  },
  actions: {
    UPDATE_CURRENT_LANGUAGE: ({ commit }, payload) => {
      commit('UPDATE_CURRENT_LANGUAGE', payload)
    },
    UPDATE_SELECTED_STORE: async ({ commit }, store) => {
      store.company = await utils.getCompany()
      commit('UPDATE_SELECTED_STORE', store)
    },
    UPDATE_MANAGED_STORE: ({ commit }, payload) => {
      commit('UPDATE_MANAGED_STORE', payload)
    },
    INIT_SELECTED_STORE: ({ commit }, payload) => {
      const user = payload.state.auth.user
      if (commons.isNull(user)) return
      commit('INIT_SELECTED_STORE', user)
    },
    CLEAN: ({ commit }, payload) => {
      commit('CLEAN', payload)
    },
    updateStaySignedIn: (context, payload) => {
      context.commit('updateStaySignedIn', payload)
    },
    addManagedStore: (context, payload) => {
      context.commit('addManagedStore', payload)
    },
    updateActivateFeatures: ({ commit }, payload) => {
      commit('updateActivateFeatures', payload)
    },
    updatePermissionKey: (context, payload) => {
      context.commit('updatePermissionKey', payload)
    },
    updateemailAddress: (context, payload) => {
      context.commit('updateemailAddress', payload)
    },
    updatePermissionValue: (context, payload) => {
      context.commit('updatePermissionValue', payload)
    },
    updatesavePreviewSet: (context, payload) => {
      context.commit('updatesavePreviewSet', payload)
    },
    updateLoginMainImage: (context, payload) => {
      context.commit('updateLoginMainImage', payload)
    },
    updateLoginLogoImage: (context, payload) => {
      context.commit('updateLoginLogoImage', payload)
    },
    updateMenuLogoImage: (context, payload) => {
      context.commit('updateMenuLogoImage', payload)
    },
    updateTimezone: (context, payload) => {
      context.commit('updateTimezone', payload)
    },
    updateSidebarMenu: (context, payload) => {
      context.commit('updateSidebarMenu', payload)
    }

  },
  namespaced: true,
  state: state
}
