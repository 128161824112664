<template>
  <div class="v-sidebar-menu" :class="sidebarClass" :style="[{'max-width': sidebarWidth}]" @mouseleave="onMouseLeave">
    <v-app id="inspire">
      <v-card class="vertical-menu">
        <v-tabs vertical v-model="selectedTab" >
          <v-tab v-for="item in menuTabItem"
            :key="item.tabIndex"
            @click="getRoute(item.tabIndex)"
            >
            <div class="v-tab-text-alignment">
            {{ item.menuText }}
            </div>
          </v-tab>
          <v-tab-item :key="'ESL'">
            <v-card flat>
              <slot name="header" />
              <h1 class="logo">
                <span v-if="MenuLogoImage === '' || MenuLogoImage === undefined || MenuLogoImage === null">
                  <img class="logoclass" src="@/assets/img/logo.svg" alt="solum esl dashboard" />
                </span>
                <span v-else>
                  <img :src="MenuLogoImage" alt="solum esl dashboard" style="width:183px;height:50px" />
                </span>
              </h1>
              <button type="button" class="btn-nav-fold">
                <img src="@/assets/img/expandMenu-small.png" alt="열기" />
              </button>

              <div class="vsm--scroll-wrapper" v-bind:class="{ 'Expandlinkopened': isWebsiteLink }"
                :style="isCollapsed && [rtl ? { 'margin-left': '-17px' } : { 'margin-right': '-17px' }]">
                <div class="vsm--list" :style="isCollapsed && { width: widthCollapsed }">
                  <perfect-scrollbar>
                    <sidebar-menu-item v-for="(item, index) in menu" :key="index" :item="item"
                      :is-collapsed="isCollapsed" :active-show="activeShow" :show-one-child="showOneChild"
                      :show-child="showChild" :rtl="rtl" :mobile-item="mobileItem" :disable-hover="disableHover"
                      @set-mobile-item="setMobileItem" @unset-mobile-item="unsetMobileItem">
                      <slot slot="dropdown-icon" name="dropdown-icon" />
                    </sidebar-menu-item>
                  </perfect-scrollbar>
                </div>
                <div v-if="isCollapsed" class="vsm--mobile-item" :style="mobileItemStyle.item">
                  <sidebar-menu-item v-if="mobileItem" :item="mobileItem" :is-mobile-item="true"
                    :mobile-item-style="mobileItemStyle" :is-collapsed="isCollapsed" :show-child="showChild" :rtl="rtl">
                    <slot slot="dropdown-icon" name="dropdown-icon" />
                  </sidebar-menu-item>
                  <transition name="slide-animation">
                    <div v-if="mobileItem" class="vsm--mobile-bg" :style="mobileItemStyle.background" />
                  </transition>
                </div>
              </div>
              <div>
                <div class="expand-links" v-show="isWebsiteLink">
                  <p class=" external-link-icon mb-3" @click="gotoSolumWebsite('website')">
                    {{ $t("SOLUM Website") }}
                    <img src="@/assets/img/external-link.png" alt="solum website" />
                  </p>
                  <p v-if="layOutDesigner.used" class="external-link-icon mb-3" @click="gotoSolumfamilysiteLD(layOutDesigner.path)">
                    {{ $t("Layout Designer") }}
                    <img src="@/assets/img/external-link.png" alt="Layout Designer" />
                  </p>
                  <p v-if="webPDA.used" class=" external-link-icon mb-3" @click="gotoSolumfamilysitePDA(webPDA.path)">
                    {{ $t("Web PDA") }}
                    <img src="@/assets/img/external-link.png" alt="PDA" />
                  </p>
                  <p v-if="dashboardService.used && accessLevel==='1'" class=" external-link-icon mb-3" @click="gotoSolumfamilysiteSwaggerDasboardWeb(dashboardService.path)">
                    {{ $t("API Docs for DashboardWeb") }}
                    <img src="@/assets/img/external-link.png" alt="swagger" />
                  </p>
                  <p v-if="portal.used && accessLevel==='1'" class=" external-link-icon mb-3" @click="gotoSolumfamilysiteSwaggerPortal(portal.path)">
                    {{ $t("API Docs for Portal") }}
                    <img src="@/assets/img/external-link.png" alt="swagger" />
                  </p>
                </div>
                <div class="expand-title" @click="showWebsiteLinks">
                  <p class="solum-family-link-title">
                    <span @click="getApiPath">{{ $t("SOLUM Family Site") }}</span>
                    <span style=" font-size: 23px ">
                      <span v-if="isWebsiteLink">-</span>
                      <span v-else @click="getApiPath">+</span>
                    </span>
                  </p>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="'LCD'" v-if="lcdTabEnable==='true'">
            <v-card flat>
              <slot name="header" />
              <h1 class="logo">
                <span v-if="MenuLogoImage === '' || MenuLogoImage === undefined || MenuLogoImage === null">
                  <img class="logoclass" src="@/assets/img/logo.svg" alt="solum esl dashboard" />
                </span>
                <span v-else>
                  <img :src="MenuLogoImage" alt="solum esl dashboard" style="width:183px;height:50px" />
                </span>
              </h1>
              <button type="button" class="btn-nav-fold">
                <img src="@/assets/img/expandMenu-small.png" alt="열기" />
              </button>
              <div class="vsm--scroll-wrapper" v-bind:class="{ 'Expandlinkopened': isWebsiteLink }"
                :style="isCollapsed && [rtl ? { 'margin-left': '-17px' } : { 'margin-right': '-17px' }]">
                <div class="vsm--list" :style="isCollapsed && { width: widthCollapsed }">
                  <perfect-scrollbar>
                    <sidebar-menu-item v-for="(item, index) in lcdItem" :key="index" :item="item"
                      :is-collapsed="isCollapsed" :active-show="activeShow" :show-one-child="showOneChild"
                      :show-child="showChild" :rtl="rtl" :mobile-item="mobileItem" :disable-hover="disableHover"
                      @set-mobile-item="setMobileItem" @unset-mobile-item="unsetMobileItem">
                      <slot slot="dropdown-icon" name="dropdown-icon" />
                    </sidebar-menu-item>
                  </perfect-scrollbar>
                </div>
                <div v-if="isCollapsed" class="vsm--mobile-item" :style="mobileItemStyle.item">
                  <sidebar-menu-item v-if="mobileItem" :item="mobileItem" :is-mobile-item="true"
                    :mobile-item-style="mobileItemStyle" :is-collapsed="isCollapsed" :show-child="showChild" :rtl="rtl">
                    <slot slot="dropdown-icon" name="dropdown-icon" />
                  </sidebar-menu-item>
                  <transition name="slide-animation">
                    <div v-if="mobileItem" class="vsm--mobile-bg" :style="mobileItemStyle.background" />
                  </transition>
                </div>
              </div>
              <div>
                <div class="expand-links" v-show="isWebsiteLink">
                  <p class=" external-link-icon mb-3" @click="gotoSolumWebsite('website')">
                    {{ $t("SOLUM Website") }}
                    <img src="@/assets/img/external-link.png" alt="solum website" />
                  </p>
                  <p v-if="layOutDesigner.used" class="external-link-icon mb-3" @click="gotoSolumfamilysiteLD(layOutDesigner.path)">
                    {{ $t("Layout Designer") }}
                    <img src="@/assets/img/external-link.png" alt="Layout Designer" />
                  </p>
                  <p v-if="webPDA.used" class=" external-link-icon mb-3" @click="gotoSolumfamilysitePDA(webPDA.path)">
                    {{ $t("Web PDA") }}
                    <img src="@/assets/img/external-link.png" alt="PDA" />
                  </p>
                  <p v-if="dashboardService.used && accessLevel==='1'" class=" external-link-icon mb-3" @click="gotoSolumfamilysiteSwaggerDasboardWeb(dashboardService.path)">
                    {{ $t("API Docs for DashboardWeb") }}
                    <img src="@/assets/img/external-link.png" alt="swagger" />
                  </p>
                  <p v-if="portal.used && accessLevel==='1'" class=" external-link-icon mb-3" @click="gotoSolumfamilysiteSwaggerPortal(portal.path)">
                    {{ $t("API Docs for Portal") }}
                    <img src="@/assets/img/external-link.png" alt="swagger" />
                  </p>
                </div>
                <div class="expand-title" @click="showWebsiteLinks">
                  <p class="solum-family-link-title">
                    <span @click="getApiPath">{{ $t("SOLUM Family Site") }}</span>
                    <span style=" font-size: 23px ">
                      <span v-if="isWebsiteLink">-</span>
                      <span v-else @click="getApiPath">+</span>
                    </span>
                  </p>
                </div>
              </div>
              <slot name="footer" />
              <button v-if="!hideToggle" class="vsm--toggle-btn"
                :class="{ 'vsm--toggle-btn_slot': $slots['toggle-icon'] }" @click="onToggleClick">
                <slot name="toggle-icon" />
              </button>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="'SYSTEM CONFIG'">
            <v-card flat>
              <slot name="header" />
            <h1 class="logo">
                <span v-if="MenuLogoImage === '' || MenuLogoImage === undefined || MenuLogoImage === null">
                  <img class="logoclass" src="@/assets/img/logo.svg" alt="solum esl dashboard" />
                </span>
                <span v-else>
                  <img :src="MenuLogoImage" alt="solum esl dashboard" style="width:183px;height:50px" />
                </span>
              </h1>
              <button type="button" class="btn-nav-fold">
                <img src="@/assets/img/expandMenu-small.png" alt="열기" />
              </button>
              <div class="vsm--scroll-wrapper" v-bind:class="{ 'Expandlinkopened': isWebsiteLink }"
                :style="isCollapsed && [rtl ? { 'margin-left': '-17px' } : { 'margin-right': '-17px' }]">
                <div class="vsm--list" :style="isCollapsed && { width: widthCollapsed }">
                  <perfect-scrollbar>
                    <sidebar-menu-item v-for="(item, index) in SystemMenu" :key="index" :item="item"
                      :is-collapsed="isCollapsed" :active-show="activeShow" :show-one-child="showOneChild"
                      :show-child="showChild" :rtl="rtl" :mobile-item="mobileItem" :disable-hover="disableHover"
                      @set-mobile-item="setMobileItem" @unset-mobile-item="unsetMobileItem">
                      <slot slot="dropdown-icon" name="dropdown-icon" />
                    </sidebar-menu-item>
                  </perfect-scrollbar>
                </div>
                <div v-if="isCollapsed" class="vsm--mobile-item" :style="mobileItemStyle.item">
                  <sidebar-menu-item v-if="mobileItem" :item="mobileItem" :is-mobile-item="true"
                    :mobile-item-style="mobileItemStyle" :is-collapsed="isCollapsed" :show-child="showChild" :rtl="rtl">
                    <slot slot="dropdown-icon" name="dropdown-icon" />
                  </sidebar-menu-item>
                  <transition name="slide-animation">
                    <div v-if="mobileItem" class="vsm--mobile-bg" :style="mobileItemStyle.background" />
                  </transition>
                </div>
              </div>
              <div class="expand-links" v-show="isWebsiteLink">
                  <p class=" external-link-icon mb-3" @click="gotoSolumWebsite('website')">
                    {{ $t("SOLUM Website") }}
                    <img src="@/assets/img/external-link.png" alt="solum website" />
                  </p>
                  <p v-if="layOutDesigner.used" class="external-link-icon mb-3" @click="gotoSolumfamilysiteLD(layOutDesigner.path)">
                    {{ $t("Layout Designer") }}
                    <img src="@/assets/img/external-link.png" alt="Layout Designer" />
                  </p>
                  <p v-if="webPDA.used" class=" external-link-icon mb-3" @click="gotoSolumfamilysitePDA(webPDA.path)">
                    {{ $t("Web PDA") }}
                    <img src="@/assets/img/external-link.png" alt="PDA" />
                  </p>
                  <p v-if="dashboardService.used && accessLevel==='1'" class=" external-link-icon mb-3" @click="gotoSolumfamilysiteSwaggerDasboardWeb(dashboardService.path)">
                    {{ $t("API Docs for DashboardWeb") }}
                    <img src="@/assets/img/external-link.png" alt="swagger" />
                  </p>
                  <p v-if="portal.used && accessLevel==='1'" class=" external-link-icon mb-3" @click="gotoSolumfamilysiteSwaggerPortal(portal.path)">
                    {{ $t("API Docs for Portal") }}
                    <img src="@/assets/img/external-link.png" alt="swagger" />
                  </p>
                </div>
                <div class="expand-title" @click="showWebsiteLinks">
                  <p class="solum-family-link-title">
                    <span @click="getApiPath">{{ $t("SOLUM Family Site") }}</span>
                    <span style=" font-size: 23px ">
                      <span v-if="isWebsiteLink">-</span>
                      <span v-else @click="getApiPath">+</span>
                    </span>
                  </p>
                </div>
                <slot name="footer" />
              <button v-if="!hideToggle" class="vsm--toggle-btn"
                :class="{ 'vsm--toggle-btn_slot': $slots['toggle-icon'] }" @click="onToggleClick">
                <slot name="toggle-icon" />
              </button>
              </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import SidebarMenuItem from '@/components/SidebarMenu/SidebarMenuItem.vue'
import { animationMixin } from '@/components/NavMixins'
// import lcdItem from '@/lcd/mixin/lcdItem'
import configs from '@/plugins/configs'
import EventBus from '@/plugins/eventBus.js'

const requests = {
  getApiPathApi: {
    method: 'get',
    url: '/api/common/config/APIPath'
  }
}
export default {
  name: 'SidebarMenu',
  components: {
    SidebarMenuItem
  },
  mixins: [animationMixin],
  props: {
    menu: {
      type: Array,
      required: true
    },
    SystemMenu: {
      type: Array,
      required: true
    },
    lcdItem: {
      type: Array,
      required: true
    },

    collapsed: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '350px'
    },
    widthCollapsed: {
      type: String,
      default: '50px'
    },
    showChild: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: ''
    },
    showOneChild: {
      type: Boolean,
      default: false
    },
    rtl: {
      type: Boolean,
      default: false
    },
    relative: {
      type: Boolean,
      default: false
    },
    hideToggle: {
      type: Boolean,
      default: false
    },
    disableHover: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isCollapsed: this.collapsed,
      mobileItem: null,
      mobileItemPos: 0,
      mobileItemHeight: 0,
      mobileItemTimeout: null,
      activeShow: null,
      parentHeight: 0,
      parentWidth: 0,
      parentOffsetTop: 0,
      parentOffsetLeft: 0,
      isWebsiteLink: false,
      selectedTab: null,
      data: [],
      menuItem: [
        { menuText: 'ESL' },
        { menuText: 'LCD' },
        { menuText: 'SYSTEM CONFIG' }
      ],
      menuPermissionList: [
        {
          status: true,
          package: 'ESL'
        },
        {
          status: false,
          package: 'LCD'
        },
        {
          status: true,
          package: 'SYSTEM CONFIG'
        }
      ],
      selectCompany: '',
      tabLoaded: true,
      MenuLogoImage: {},
      systemConfigMenuAccess: false,

      // Family Link

      dashboardService: { path: '/dashboard', used: true },
      layOutDesigner: { path: '/LD', used: true },
      // lbsManager: { path: '/lbs', used: true },
      // otaManager: { path: '/dashboardWeb/common/otamanager', used: true },
      portal: { path: '/', used: true },
      webPDA: { path: '/PDA', used: true },
      accessLevel: this.$store.state.auth.user.accountInfo.accessLevel,
      lcdTabEnable: localStorage.getItem('LcdTabEnable'),
      verticalMenuItem: [
        { menuText: 'ESL', tabIndex: 0 },
        { menuText: 'LCD', tabIndex: 1 },
        { menuText: 'SYSTEM CONFIG', tabIndex: 2 }
      ],
      tabactivate: false
    }
  },
  mounted () {
    var sysAccesMenu = this.$store.state.auth.user.accountInfo.accessMenu
    if (sysAccesMenu.indexOf('9100') !== -1 || sysAccesMenu.indexOf('9200') !== -1 || sysAccesMenu.indexOf('9300') !== -1 || sysAccesMenu.indexOf('9400') !== -1 || sysAccesMenu.indexOf('9500') !== -1) {
      this.systemConfigMenuAccess = true
    } else {
      this.systemConfigMenuAccess = false
    }
    const tabIndex = sessionStorage.getItem('tabIndex')
    this.selectedTab = tabIndex !== null ? tabIndex : 0
    this.selectedTab = parseInt(this.selectedTab)
    this.selectCompany = this.$store.state.dataStore.selectedStore.company
    this.MenuLogoImage = this.$store.state.dataStore.MenuLogoImage
    setTimeout(() => {
      this.lcdTabEnable = localStorage.getItem('LcdTabEnable')
    }, 500)
    this.getApiPath()
  },
  computed: {
    sidebarWidth () {
      return this.isCollapsed ? this.widthCollapsed : this.width
    },
    sidebarClass () {
      return [
        !this.isCollapsed ? 'vsm_expanded' : 'vsm_collapsed',
        this.theme ? `vsm_${this.theme}` : '',
        this.rtl ? 'vsm_rtl' : '',
        this.relative ? 'vsm_relative' : ''
      ]
    },
    menuTabItem () {
      var menu
      if (this.lcdTabEnable === 'false' && this.systemConfigMenuAccess === true) {
        menu = [
          // { menuText: 'ESL', tabIndex: 0 },
          // { menuText: 'SYSTEM CONFIG', tabIndex: 1 }
          { menuText: this.$t('ESL'), tabIndex: 0 },
          // { menuText: 'LCD', tabIndex: 1 },
          { menuText: this.$t('SYSTEM CONFIG'), tabIndex: 2 }
        ]
      } else if (this.lcdTabEnable === 'true' && this.systemConfigMenuAccess === true) {
        menu = [
          { menuText: this.$t('ESL'), tabIndex: 0 },
          { menuText: this.$t('LCD'), tabIndex: 1 },
          { menuText: this.$t('SYSTEM CONFIG'), tabIndex: 2 }
        ]
      } else if (this.lcdTabEnable === 'true' && this.systemConfigMenuAccess === false) {
        menu = [
          { menuText: this.$t('ESL'), tabIndex: 0 },
          { menuText: this.$t('LCD'), tabIndex: 1 }
        ]
      } else if (this.lcdTabEnable === 'false' && this.systemConfigMenuAccess === false) {
        menu = [
          { menuText: this.$t('ESL'), tabIndex: 0 }
        ]
      }
      return menu
    },
    mobileItemStyle () {
      return {
        item: [
          { position: 'absolute' },
          { top: `${this.mobileItemPos}px` },
          this.rtl ? { right: '0px' } : { left: '0px' },
          this.rtl ? { 'padding-right': this.sidebarWidth } : { 'padding-left': this.sidebarWidth },
          this.rtl && { direction: 'rtl' },
          { 'z-index': 0 },
          { width: `${this.parentWidth - this.parentOffsetLeft}px` },
          { 'max-width': this.width }
        ],
        dropdown: [
          { position: 'absolute' },
          { top: `${this.mobileItemHeight}px` },
          { width: '100%' },
          { 'max-height': `${this.parentHeight - (this.mobileItemPos + this.mobileItemHeight) - this.parentOffsetTop}px` },
          { 'overflow-y': 'auto' }
        ],
        background: [
          { position: 'absolute' },
          { top: '0px' },
          { left: '0px' },
          { right: '0px' },
          { width: '100%' },
          { height: `${this.mobileItemHeight}px` },
          { 'z-index': -1 }
        ]
      }
    }
  },
  watch: {
    collapsed (val) {
      if (this.isCollapsed === this.collapsed) return
      this.isCollapsed = val
      this.unsetMobileItem()
    },
    selectedTab (val, oldVal) {
      const tabIndex = parseInt(val)
      this.selectedTab = tabIndex
      sessionStorage.setItem('tabIndex', tabIndex)
    }
  },
  created () {
    const toPath = this.$store.state.dataStore.sideMenu
    if (toPath === 'SYSTEM_CONFIG') {
      if (this.lcdTabEnable === 'false') {
        this.selectedTab = 1
        sessionStorage.setItem('tabIndex', this.selectedTab)
      } else {
        this.selectedTab = 2
        sessionStorage.setItem('tabIndex', this.selectedTab)
      }
    } else if (toPath === 'LCD') {
      this.selectedTab = 1
      sessionStorage.setItem('tabIndex', this.selectedTab)
    } else {
      this.selectedTab = 0
      sessionStorage.setItem('tabIndex', this.selectedTab)
    }
    EventBus.$on('TabActivate', (data) => {
      if (data === 'SYSTEM_CONFIG') {
        this.$store.dispatch('dataStore/updateSidebarMenu', 'SYSTEM_CONFIG')
        if (this.lcdTabEnable === 'false') {
          this.selectedTab = 1
        } else {
          this.selectedTab = 2
        }
      } else if (data === 'LCD') {
        this.$store.dispatch('dataStore/updateSidebarMenu', 'LCD')
        this.selectedTab = 1
      } else {
        this.$store.dispatch('dataStore/updateSidebarMenu', '')
        this.selectedTab = 0
      }
    })
  },
  methods: {
    isSideMenuPermission (key) {
      if (this.menuPermissionList.length > 0) {
        const packageArr = this.menuPermissionList.filter((el) => el.status && el.package === key)
        return packageArr.length
      } else {
        return false
      }
    },
    getRoute (value) {
      // showing system config side bar menu link based on user login
      var codeValue = this.$store.state.auth.user.accountInfo.accessMenu
      var accessCodeValue = []
      codeValue.filter((item) => {
        if (item === '9100' || item === '9200' || item === '9300' || item === '9400' || item === '9500') {
          accessCodeValue.push(Math.min(item))
        }
      })

      var sysRouterPath = accessCodeValue.sort()
      // end of side bar menu for system config
      if (value === 0) {
        if (this.$route.path !== '/') {
          this.$router.push('/')
        }
      } else if (value === 1) {
        if (this.$route.path !== '/Lcd/Overview') {
          this.$router.push('/Lcd/Overview')
        }
      } else if (value === 2) {
        if (this.$route.path !== '/System/DashboardConfig') {
          if (sysRouterPath[0] === 9100) {
            this.$router.push('/System/DashboardConfig')
          } else if (sysRouterPath[0] === 9200 && this.$route.path !== '/System/HardwareSetting') {
            this.$router.push('/System/HardwareSetting')
          } else if (sysRouterPath[0] === 9300 && this.$route.path !== '/System/AppConfig') {
            this.$router.push('/System/AppConfig')
          } else if (sysRouterPath[0] === 9500 && this.$route.path !== '/System/ProductFileConfig') {
            this.$router.push('/System/ProductFileConfig')
          } else if (sysRouterPath[0] === 9400) {
            if (sysRouterPath[1] === 9500 && this.$route.path !== '/System/ProductFileConfig') {
              this.$router.push('/System/ProductFileConfig')
            } else {
              if (sysRouterPath[0] === 9400 && this.$route.path !== '/System/SystemLicense') {
                this.$router.push('/System/SystemLicense')
              }
            }
          }
        }
      }
    },
    onMouseLeave () {
      this.unsetMobileItem()
    },
    onToggleClick () {
      this.isCollapsed = !this.isCollapsed
      this.unsetMobileItem()
      this.$emit('toggle-collapse', this.isCollapsed)
    },
    onActiveShow (item) {
      this.activeShow = item
    },
    onItemClick (event, item, node) {
      this.$emit('item-click', event, item, node)
    },
    setMobileItem ({ item, itemEl }) {
      if (this.mobileItem === item) return
      const sidebarTop = this.$el.getBoundingClientRect().top
      const itemTop = itemEl.getBoundingClientRect().top
      const itemLinkEl = itemEl.children[0]

      const styles = window.getComputedStyle(itemEl)
      const paddingTop = parseFloat(styles.paddingTop)
      const marginTop = parseFloat(styles.marginTop)

      const height = itemLinkEl.offsetHeight
      const positionTop = itemTop - sidebarTop + paddingTop + marginTop

      this.unsetMobileItem()
      this.$nextTick(() => {
        this.initParentOffsets()
        this.mobileItem = item
        this.mobileItemPos = positionTop
        this.mobileItemHeight = height
      })
    },
    unsetMobileItem (delay) {
      if (this.mobileItemTimeout) clearTimeout(this.mobileItemTimeout)
      if (!delay) {
        this.mobileItem = null
        return
      }
      this.mobileItemTimeout = setTimeout(() => {
        this.mobileItem = null
      }, 600)
    },
    initParentOffsets () {
      const { top: sidebarTop, left: sidebarLeft, right: sidebarRight } = this.$el.getBoundingClientRect()
      const parent = this.relative ? this.$el.parentElement : document.documentElement
      this.parentHeight = parent.clientHeight
      this.parentWidth = parent.clientWidth
      if (this.relative) {
        const { top: parentTop, left: parentLeft } = parent.getBoundingClientRect()
        this.parentOffsetTop = sidebarTop - (parentTop + parent.clientTop)
        this.parentOffsetLeft = this.rtl ? this.parentWidth - sidebarRight + (parentLeft + parent.clientLeft) : sidebarLeft - (parentLeft + parent.clientLeft)
      } else {
        this.parentOffsetTop = sidebarTop
        this.parentOffsetLeft = this.rtl ? this.parentWidth - sidebarRight : sidebarLeft
      }
    },
    onItemUpdate (newItem, item) {
      if (item === this.mobileItem) {
        this.mobileItem = newItem
      }
      if (item === this.activeShow) {
        this.activeShow = newItem
      }
    },
    showWebsiteLinks () {
      this.isWebsiteLink = !this.isWebsiteLink
    },
    gotoSolumWebsite (path) {
      window.open('https://solumesl.com/', '_blank')
      this.isWebsiteLink = false
    },
    gotoSolumfamilysiteLD (path) {
      window.open(configs.ServerAddress + path, '_blank')
      this.isWebsiteLink = false
    },
    gotoSolumfamilysitePDA (path) {
      window.open(configs.ServerAddress + path, '_blank')
      this.isWebsiteLink = false
    },
    gotoSolumfamilysiteSwaggerDasboardWeb (path) {
      window.open(configs.ServerAddress + path + '/swagger-ui/index.html#/', '_blank')
      this.isWebsiteLink = false
    },
    gotoSolumfamilysiteSwaggerPortal (path) {
      if (path === '/') {
        window.open(configs.ServerAddress + path + 'swagger-ui/index.html#/', '_blank')
      } else {
        window.open(configs.ServerAddress + path + '/swagger-ui/index.html#/', '_blank')
      }
      this.isWebsiteLink = false
    },
    gotToMenuPage (item, index) {
      this.data = item
      this.menuItem.splice(index, 1)
      this.menuItem.unshift(this.data)
      this.activeIndex = index

      if (item.menuItem === 'ESL') {
        if (this.$route.path !== '/') {
          this.$router.push('/')
        }
      } else if (item.menuItem === 'LCD') {
        if (this.$route.path !== '/Lcd/Overview') {
          this.$router.push('/Lcd/Overview')
        }
      }
    },
    getApiPath () {
      const params = {}
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.getApiPathApi.method,
          requests.getApiPathApi.url,
          config
        )
        .then((res) => {
          this.dashboardService.path = res.data.dashboardService.path
          this.dashboardService.used = res.data.dashboardService.used

          this.layOutDesigner.path = res.data.layOutDesigner.path
          this.layOutDesigner.used = res.data.layOutDesigner.used

          // this.lbsManager.path = res.data.lbsManager.path
          // this.lbsManager.used = res.data.lbsManager.used

          this.portal.path = res.data.portal.path
          this.portal.used = res.data.portal.used

          this.webPDA.path = res.data.webPDA.path
          this.webPDA.used = res.data.webPDA.used

          // this.otaManager.path = res.data.otaManager.path
          // this.otaManager.used = res.data.otaManager.used
        })
    }

  },
  provide () {
    return {
      emitActiveShow: this.onActiveShow,
      emitItemClick: this.onItemClick,
      emitItemUpdate: this.onItemUpdate
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/vue-sidebar-menu";
.v-tab-text-alignment {
  transform: rotate(270deg);
  left: -54px;
  padding-left: 0px;
}
.v-tabs--vertical > .v-tabs-bar .v-tab {
  min-height: 145px;
  min-width: 150px;
  padding-left: 50px;
}
.v-tabs-bar__content {
  width: 46px;
}
.v-sheet.v-card {
  border-radius: 0;
}
.v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu .vsm--link_level-1.vsm--link_active {
  box-shadow: 7px 0px 0px 0px #697a8a inset !important;
}
</style>
<style scoped>
.esl-tab-active {
  height: 100% !important;
}
/*.v-tab.lbs-tab, .v-tab.esl-tab{
  height: 33.33% !important;
}*/
.v-tabs .v-tab.lbs-tab:nth-child(1):nth-last-child(3),
.v-tabs .v-tab.lbs-tab:nth-child(2):nth-last-child(2),
.v-tabs .v-tab.lbs-tab:nth-child(3):nth-last-child(1) {
  height: 33.33% !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab {
  /* min-height: 216px !important; */
  left: -85px !important;
  /* min-width: 216px !important; */
  /* height: 50% !important; */
  width: 180px !important;
}
.vertical-menu ::v-deep .v-slide-group__content a:nth-child(3) div {
  padding-left: 80px;
}
.vertical-menu ::v-deep .v-slide-group__content a:nth-child(4) div {
  padding-left: 80px;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  border: 2px solid #00000033 !important;
}
.theme--light.v-tabs-items {
  background-color: #001e38 !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  background: #e2e2e2 !important;
  font-weight: bold;
}
.v-tab {
  letter-spacing: 0 !important;
}
.v-tab--active {
  font-weight: bolder !important;
  background: #09263f !important;
  /* height: 15% !important; */
  color: white !important;
}
.theme--light.v-card {
  background: #09263f !important;
  border: none;
  height: 100%;
}
.v-tabs--vertical {
  height: 100% !important;
}
/*  */
/* .vertical-menu ::v-deep .v-tabs-bar{
   position: fixed;
   z-index: 111;
   bottom: 66px;
 }
 .vertical-menu ::v-deep .v-window{
   position: relative;
   left: 46.5px;
 }
 .vertical-menu ::v-deep .v-tabs .v-item-group:nth-child(2) {
   max-width: 85.5% !important;
 }
 .v-sidebar-menu ::v-deep .v-application--wrap{
   min-height: 0 !important;
 } */
.ps {
  /* height: calc(100vh - 183px); */
  height: calc(100vh - 130px);
  overflow: auto;
}
.vertical-menu {
  height: calc(100vh - 0px) !important;
}
.vertical-menu .v-tab {
  flex: 1 !important;
}
.logoclass{
  height: 19px;
  width: 223px;
}
</style>
